$textShadow: 1px 1px 10px #ffffff, 1px 1px 10px #ffffff;
$textShadowHover: 1px 1px 10px #ffffff, 1px 1px 10px #6df1d8;
$border: linear-gradient(45deg, #d30cb8, #6df1d8);
$background: #000;

nav {
  background-color: $background;
  border-width: 0 0 4px 0;
  border-style: solid;
  border-image-source: $border;
  border-image-slice: 1;

  & .brand-logo,
  a {
    color: #fff;
    text-shadow: $textShadow;

    &:hover {
      transform: scale(1.1);
    }
  }

}

.sidenav {
  background-color: $background;
  li {
    border-width: 0 0 2px 0;
    border-style: solid;
    border-image-source: $border;
    border-image-slice: 1;

    a,
    a i {
      color: #fff;
      text-shadow: $textShadow;
    }
  }
}
