$background: linear-gradient(45deg, #d30cb8, #6df1d8);

.intro {
  padding: 20px 0;
  background-color: #e2e2e2;

  &__image-container {
    img {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }

  & .highlight {
    background: linear-gradient(45deg, #d30cb8, #6df1d8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.info {
  .card {
    border-radius: 12px;
  }
  .skill-title {
    font-weight: bold;
  }
}
